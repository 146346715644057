// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: "https://api-bullclaimer.gpkit.link",
  // apiUrl: "https://localhost:7092",
  marketplaceContract: "0xf3793577cc99aff99aca8613b9bad18808a209f4",
  claimingContract: "0x54AA093Bcf682489A058A8043153Df1413f113DC",
  GMPD_NFT: "0xf4949D4f888641399ADb83b2b25f44b276c8E94B",
  BUSD_CURRENCY:"0xDaaa52B192743f57f8dC4A26F9287ADCdaAA7ba3",
  defaultChainId: '0x61', // BNB test net
  networkData : [
      {
        chainId: '0x61',
        chainName: 'BNB test chain',
        nativeCurrency: {
          name: 'BNB',
          symbol: 'bnb',
          decimals: 18,
        },
        rpcUrls: [
          'https://data-seed-prebsc-1-s1.binance.org:8545',
          'https://data-seed-prebsc-2-s1.binance.org:8545',
          'https://data-seed-prebsc-1-s2.binance.org:8545',
        ],
        blockExplorerUrls: ['https://testnet.bscscan.com/'],
      }, // bnb test
      {
        chainId: '0xaa36a7',
        chainName: 'Sepolia',
        nativeCurrency: {
          name: 'ETH',
          symbol: 'eth',
          decimals: 18,
        },
        rpcUrls: [
          'https://rough-omniscient-liquid.ethereum-sepolia.quiknode.pro/9b1b5ee755744aebb3266c02591f7b12648f10b6/'
        ],
        blockExplorerUrls: ['https://sepolia.etherscan.io/'],

      }, // eth test
      {
        chainId: '0x13882',
        chainName: 'Polygon Test',
        nativeCurrency: {
          name: 'MATIC',
          symbol: 'MATIC',
          decimals: 18,
        },
        rpcUrls: [
          'https://icy-attentive-isle.matic-amoy.quiknode.pro/cf5d4273a118ff569acd5c19296028b09fe98502/',
      ],
        blockExplorerUrls: ['https://amoy.polygonscan.com/'],
      }, // polygon test
      {
        chainId: '0xa869',
        chainName: 'Avalanche FUJI C-Chain',
        nativeCurrency: {
          name: 'AVAX',
          symbol: 'avax',
          decimals: 18,
        },
        rpcUrls: [
          'https://api.avax-test.network/ext/bc/C/rpc'
        ],
        blockExplorerUrls: ['https://testnet.snowtrace.io/'],
      }, // avalanche test
      {
        chainId: '0x38',
        chainName: 'BNB chain',
        nativeCurrency: {
          name: 'BNB',
          symbol: 'bnb',
          decimals: 18,
        },
        rpcUrls: [
          'https://bsc-dataseed1.ninicoin.io',
          'https://bsc-dataseed1.defibit.io',
          'https://bsc-dataseed.binance.org',
        ],
        blockExplorerUrls: ['https://bscscan.com/'],
      }, // bnb main
      {
        chainId: '0x1',
        chainName: 'Etherium',
        nativeCurrency: {
          name: 'ETH',
          symbol: 'eth',
          decimals: 18,
        },
        rpcUrls: ['https://speedy-nodes-nyc.moralis.io/fde6998ca86206597a0acf43/eth/mainnet'],
        blockExplorerUrls: ['https://etherscan.io'],
      }, // eth main
      {
        chainId: '0x89',
        chainName: 'Polygon',
        nativeCurrency: {
          name: 'MATIC',
          symbol: 'MATIC',
          decimals: 18,
        },
        rpcUrls: ['https://speedy-nodes-nyc.moralis.io/fde6998ca86206597a0acf43/polygon/mainnet'],
        blockExplorerUrls: ['https://polygonscan.com/'],
      },
      {
        chainId: '0xa86a',
        chainName: 'Avalanche',
        nativeCurrency: {
          name: 'AVAX',
          symbol: 'AVAX',
          decimals: 18,
        },
        rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
        blockExplorerUrls: ['https://snowtrace.io/'],
      }, // avalanche main
      {
        chainId: '0x141',
        chainName: 'KCC',
        nativeCurrency: {
          name: 'KCS',
          symbol: 'KCS',
          decimals: 18,
        },
        rpcUrls: ['https://rpc-mainnet.kcc.network'],
        blockExplorerUrls: ['https://explorer.kcc.io/en'],
      },
      {
        chainId: '0x142',
        chainName: 'KCC_Test',
        nativeCurrency: {
          name: 'KCS',
          symbol: 'KCS',
          decimals: 18,
        },
        rpcUrls: ['https://rpc-testnet.kcc.network'],
        blockExplorerUrls: ['https://scan-testnet.kcc.network'],
      },
      {
        chainId: '0xa4b1',
        chainName: 'Arbitrum One',
        nativeCurrency: {
          name: 'ETH',
          symbol: 'ETH',
          decimals: 18,
        },
        rpcUrls: ['https://arbitrum-mainnet.infura.io'],
        blockExplorerUrls: ['https://explorer.arbitrum.io'],
      },
      {
        chainId: '0x66eee',
        chainName: 'Arbitrum Sepolia',
        nativeCurrency: {
          name: 'ETH',
          symbol: 'ETH',
          decimals: 18,
        },
        rpcUrls: ['https://sepolia-rollup.arbitrum.io/rpc'],
        blockExplorerUrls: ['https://sepolia.arbiscan.io'],
      },
      {
        chainId: '0x2105',
        chainName: 'Base',
        nativeCurrency: {
          name: 'ETH',
          symbol: 'ETH',
          decimals: 18,
        },
        rpcUrls: ['https://mainnet.base.org'],
        blockExplorerUrls: ['https://basescan.org'],
      },
      {
        chainId: '0x14a34',
        chainName: 'Base Sepolia',
        nativeCurrency: {
          name: 'ETH',
          symbol: 'ETH',
          decimals: 18,
        },
        rpcUrls: ['https://sepolia.base.org'],
        blockExplorerUrls: ['https://sepolia-explorer.base.org'],
      },
      {
        chainId: '0x13e31',
        chainName: 'Blast',
        nativeCurrency: {
          name: 'ETH',
          symbol: 'ETH',
          decimals: 18,
        },
        rpcUrls: ['https://rpc.blast.io'],
        blockExplorerUrls: ['https://blastscan.io'],
      },
      {
        chainId: '0xa0c71fd',
        chainName: 'Blast Sepolia',
        nativeCurrency: {
          name: 'ETH',
          symbol: 'ETH',
          decimals: 18,
        },
        rpcUrls: ['https://sepolia.blast.io'],
        blockExplorerUrls: ['https://sepolia.blastexplorer.io'],
      },
      {
        chainId: '0x2802',
        chainName: 'Arthera',
        nativeCurrency: {
          name: 'AA',
          symbol: 'AA',
          decimals: 18,
        },
        rpcUrls: ['https://rpc.arthera.net'],
        blockExplorerUrls: ['https://explorer.arthera.net'],
      },
      {
        chainId: '0x2803',
        chainName: 'Arthera Testnet',
        nativeCurrency: {
          name: 'AA',
          symbol: 'AA',
          decimals: 18,
        },
        rpcUrls: ['https://rpc-test.arthera.net'],
        blockExplorerUrls: ['https://explorer-test.arthera.net'],
      },
    ],
    solana: {
      claimingProgramId: '7voBbzWraBos19EjGTrrHje9ZqMtanApZ42ttyj5UVpM',
      claimingProgramIdV2: 'H6FcsVrrgPPnTP9XicYMvLPVux9HsGSctTAwvaeYfykD',
      claimingProgramIdV3: '2R3GAXBHhXcZgxre7N6PUgRRXqFiNx8MPiZD8iTVWnnd',
      rpcUrls: [
        'https://api.devnet.solana.com',
        'https://rpc.ankr.com/solana_devnet'
      ]
    },
    elrond : {
      proxyUrl: 'https://devnet-api.elrond.com',
      explorerUrl: 'https://devnet-explorer.elrond.com',
      multiSenderAddress: 'erd1qqqqqqqqqqqqqpgqtqg5ak0k7jukkzxsdqd88ee6zf9c8p4yzy9qw67pak'
    },
    ton: {
      claimingAddress: 'kQAQRmE0QE5EldQUv499oqh0PadyPQDX7Ub86VljS76ZdGXO',
      endpoint: 'https://testnet.toncenter.com/api/v2/jsonRPC',
      apiKey: '317c395c4e2bb69e750f6b22c4d8a2bf7e77ce683175dd70b193400654c0a4f2',
      testnet: true,
      manifestUrl: 'https://bullclaimer.gpkit.link/assets/tonconnect-manifest.json'
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
