import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LockDTO } from 'src/app/dto/lock.dto';
import { UnlockDTO } from 'src/app/dto/unlock.dto';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { WalletConnectorService } from 'src/app/services/wallet-connector.service';
import {
  EVM_TOKEN_PERCENTAGE_DECIMAL,
  Web3Service,
} from 'src/app/services/web3.service';
import {
  SOLANA_TOKEN_PERCENTAGE_DECIMAL,
  SolanaWeb3Service,
} from 'src/app/services/solana.service';
import { BigNumber } from 'bignumber.js';
import { WalletDTO } from '../../dto/wallet.dto';
import { PopupService } from 'src/app/services/popup.service';
import { ClaimService } from 'src/app/services/claim.service';
import { catchError } from 'rxjs/operators';
import {interval, of, Subscription} from 'rxjs';
import swalert from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { SelectWalletComponent } from '../dialogs/select-wallet/select-wallet.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { SolanaWeb3ServiceV2 } from 'src/app/services/solanav2.service';
import { SolanaWeb3ServiceV3 } from "src/app/services/solanav3.service";
import moment from 'moment';
import { TON_TOKEN_PERCENTAGE_DECIMAL, TonService } from 'src/app/services/ton.service';

@Component({
  selector: 'app-project-info',
  templateUrl: './project-info.component.html',
  styleUrls: ['./project-info.component.scss'],
})
export class ProjectInfoComponent implements OnInit, OnDestroy, AfterViewInit {
  public lockInfo: LockDTO;
  public unlockGroups: any;
  public totalAmount: string;
  public claimedAmount: string;
  public leftAmount: string;
  public claimedPercent: number = 0;
  public newWallet?: string;
  public solanaPercentageDecimal = SOLANA_TOKEN_PERCENTAGE_DECIMAL;
  public evmPercentageDecimal = EVM_TOKEN_PERCENTAGE_DECIMAL;
  public isCountDown: boolean = true;
  public canClaim: boolean = false;
  public tabActive: boolean = true;
  public isRefundRequested: boolean;
  public isRefunded: boolean;
  public vestingStoppedTime: any;
  refreshInterval: any;
  timeLeft: any = {};
  private subscription!: Subscription;
  constructor(
    private readonly web3: Web3Service,
    private readonly solanaV1: SolanaWeb3Service,
    private readonly solanaV2: SolanaWeb3ServiceV2,
    private readonly solanaV3: SolanaWeb3ServiceV3,
    private readonly ton: TonService,
    private readonly auth: AuthService,
    private readonly walletConnector: WalletConnectorService,
    private readonly api: ApiService,
    private readonly route: ActivatedRoute,
    private readonly popupService: PopupService,
    private readonly claimService: ClaimService,
    private readonly dialog: MatDialog
  ) {
    this.lockInfo = this.route.snapshot.data.lockInfo;

    this.unlockGroups = this.generateGroups(this.lockInfo.unlocks);
  }

  @HostListener('document:visibilitychange', ['$event'])
  public visibilitychange($event: any): void {
    if (document.hidden) {
      this.tabActive = false;
    } else {
      this.tabActive = true;
      this.calculateUnlocksTimeLeft();
    }
  }

  private calculateUnlocksTimeLeft() {
    this.unlockGroups = this.generateGroups(
      this.lockInfo.unlocks.map((item: UnlockDTO) => {
        item.timeLeft = this.calculateTimeLeft(item);
        return item;
      })
    );
  }

  async ngOnInit(): Promise<void> {
    this.walletConnector.initSolana();
    this.walletConnector.initTon();
    this.isRequestRefunded();
    this.startRefreshing();
    this.calculateRefundDeadlineLeft();
    this.subscription = interval(1000).subscribe(() => {
      this.calculateRefundDeadlineLeft();
    });
    if (this.isConnected) {
      this.vestingStoppedTime = this.lockInfo.version > 0 ?
        await this.web3.vestingStoppedTime(this.lockInfo.version, this.lockInfo.contractAddress) :
        0;
    }
    if (this.lockInfo.blockchain === "ton") {
      this.ton.status$.subscribe(async status => { 
        if (status.connected === true && this.isAvailable) {
          await this.isTokenRefunded(this.lockInfo);
          this.getInfo();
        }
      });
    }

    if (this.lockInfo.blockchain === "solana") {
      if (this.lockInfo.isV3) {
        this.solanaV3.initialized$.subscribe(x => {
          if (x && this.isAvailable) {
            this.getInfo();
          }
        })
      }
      else if (this.lockInfo.isV2) {
        this.solanaV2.initialized$.subscribe(x => {
          if (x && this.isAvailable) {
            this.getInfo();
          }
        })
      }
    }
    else {
      if (this.isAvailable) {
        this.getInfo();
      }
    }
  }

  ngOnDestroy() {
    this.stopRefreshing();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  async ngAfterViewInit(): Promise<void> {
    if (this.isConnected) {
      await this.isTokenRefunded(this.lockInfo);
    }
  }

  private solana(lock: LockDTO) {
    return lock.isV3 ? this.solanaV3 : (lock.isV2 ? this.solanaV2 : this.solanaV1);
  }

  calculateRefundDeadlineLeft(): void {
    const now = new Date().getTime();
    const timeDifference = this.lockInfo.refundDate - now;

    if (timeDifference > 0) {
      this.timeLeft = {
        days: Math.floor(timeDifference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((timeDifference % (1000 * 60)) / 1000)
      };
    } else {
      this.timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      };
    }
  }

  startRefreshing() {
    this.refreshInterval = setInterval(() => {
      this.isRequestRefunded();
    }, 5000);
  }

  stopRefreshing() {
    clearInterval(this.refreshInterval);
  }

  public get isConnected(): boolean {
    return this.web3.isConnected && this.auth.isAuthorized();
  }

  public get isSolanaConnected(): boolean {
    return this.solanaV3.isConnected && this.auth.isAuthorized();
  }

  public get isTonConnected(): boolean {
    return this.ton.isConnected && this.auth.isAuthorized();
  }

  public get currentWallet() {
    return this.web3.currentAccountValue;
  }

  public get isAvailable(): boolean {
    if (this.isConnected) {
      return this.lockInfo?.isUserParticipated;
    }
    if (this.isSolanaConnected) {
      //console.log('isAvailable',  this.lockInfo?.claimingWallets.some((x) => x.address === this.solana.currentAccountValue))
      return this.lockInfo?.claimingWallets.some(
        (x) => x.address === this.solanaV3.currentAccountValue
      );
    }
    if (this.isTonConnected) {
      return this.lockInfo?.claimingWallets.some(
        (x) => x.address === this.ton.currentAccountValue
      );
    }
    return false;
  }

  public isAirdropped(unlock: UnlockDTO): boolean {
    return unlock.isUnlockedBeforeStart;
  }

  public generateGroups(unlocks: UnlockDTO[]): any[] {
    if (!unlocks) return [];
    //console.log('generateGroups', unlocks);
    // case 1
    // all airdropped (no isUnlockedBeforeStart = false)

    // case 2
    // all not-aridropped (no isUnlockedBeforeStart = true)

    // if(!unlocks.filter(i=>i.isUnlockedBeforeStart == false).length || !unlocks.filter(i=>i.isUnlockedBeforeStart == true).length){
    //   return [unlocks];
    // }

    // case 3 mixed (both are presented)

    const groupped = [];
    let newgroup = false;
    let tmpGroupStorage = [];
    for (let i = 0; i < unlocks.length; i++) {
      if (unlocks[i].isUnlockedBeforeStart) {
        if (!newgroup) newgroup = true;
        tmpGroupStorage.push(unlocks[i]);
      }
      if (!unlocks[i].isUnlockedBeforeStart) {
        if (newgroup) {
          newgroup = false;
          groupped.push(tmpGroupStorage);
          tmpGroupStorage = [];
        }
        groupped.push([unlocks[i]]);
      }
    }
    return groupped;
  }

  public isClaimed(unlock: UnlockDTO): boolean {
    if (
      !this.isAvailable ||
      !this.claimedAmount ||
      !this.unlockPassed(unlock)
    ) {
      return false;
    } else if (!this.lockInfo.claimable) {
      return true;
    }

    const walletAllocation = this.getWalletAllocation();
    const unlockAmount = new BigNumber(walletAllocation).multipliedBy(
      new BigNumber(unlock.percentage / this.lockInfo.id <= 2 ? 100 : 100000000)
    );
    return new BigNumber(this.claimedAmount).gte(unlockAmount);
  }

  public async getInfo(): Promise<void> {
    this.totalAmount = this.getWalletAllocation();
    await this.checkIfLockStopped();
    await this.getAvailableAmount(this.lockInfo);
  }

  public async claimToken(lock: LockDTO, unlock: UnlockDTO): Promise<void> {
    //check
    if (!this.isValidNetwork(lock.networkId)) return;
    if (lock.version <= 1) {
      let user,
        result = false;

      if (this.isConnected) {
        //user = this.lockInfo.claimingWallets.filter(i => i.address.toLowerCase() == this.web3.currentAccountValue.toLowerCase())[0];
        result = await this.claimService.claimToken(lock);
      }
      else if (this.isSolanaConnected) {
        user = this.lockInfo.claimingWallets.filter(
          (i) => i.address == this.solana(lock).currentAccountValue
        )[0];
        result = await this.solana(lock).claimToken(
          lock.contractAddress,
          lock.claimingWallets,
          user.originalAddress
        );
      }
      else if (this.isTonConnected) {
        user = this.lockInfo.claimingWallets.filter(
          (i) => i.address == this.ton.currentAccountValue
        )[0];
        result = await this.ton.claimToken(
          lock.contractAddress,
          lock.vaultAddress,
          lock.claimingWallets,
          user.originalAddress
        );
      }

      if (result) {
        unlock.canClaim = false;
        await this.getInfo();
      }
    } else if (lock.version >= 2) {
      let isRefundRequested: boolean;

      if (lock.blockchain === "ton") {
        isRefundRequested = await this.ton.isRefundRequested(lock.contractAddress);
      }
      else if (lock.blockchain === "solana") {
        isRefundRequested = await this.solana(lock).isRefundRequested(lock.contractAddress);
      }
      else {
        isRefundRequested = await this.web3.isRefundRequested(lock.contractAddress)
      }

      if (isRefundRequested) {
        const isClaimingContinue = await this.popupService.alreadyRefunded();

        if (isClaimingContinue) {
          let user,
            result = false;

          if (this.isConnected) {
            //user = this.lockInfo.claimingWallets.filter(i => i.address.toLowerCase() == this.web3.currentAccountValue.toLowerCase())[0];
            result = await this.claimService.claimToken(lock);
            await this.isTokenRefunded(lock);
          }
          else if (this.isSolanaConnected) {
            user = this.lockInfo.claimingWallets.filter(
              (i) => i.address == this.solanaV3.currentAccountValue
            )[0];
            result = await this.solana(lock).claimToken(
              lock.contractAddress,
              lock.claimingWallets,
              user.originalAddress
            );
          }
          else if (this.isTonConnected) {
            user = this.lockInfo.claimingWallets.filter(
              (i) => i.address == this.ton.currentAccountValue
            )[0];
            result = await this.ton.claimToken(
              lock.contractAddress,
              lock.vaultAddress,
              lock.claimingWallets,
              user.originalAddress
            );
          }
          if (result) {
            unlock.canClaim = false;
            await this.getInfo();
          }
        } else {
          return;
        }
      } else {
        let user,
          result = false;

        if (this.isConnected) {
          //user = this.lockInfo.claimingWallets.filter(i => i.address.toLowerCase() == this.web3.currentAccountValue.toLowerCase())[0];
          result = await this.claimService.claimToken(lock);
          await this.isTokenRefunded(lock);
        }
        else if (this.isSolanaConnected) {
          user = this.lockInfo.claimingWallets.filter(
            (i) => i.address == this.solanaV3.currentAccountValue
          )[0];
          result = await this.solana(lock).claimToken(
            lock.contractAddress,
            lock.claimingWallets,
            user.originalAddress
          );
        }
        else if (this.isTonConnected) {
          user = this.lockInfo.claimingWallets.filter(
            (i) => i.address == this.ton.currentAccountValue
          )[0];
          result = await this.ton.claimToken(
            lock.contractAddress,
            lock.vaultAddress,
            lock.claimingWallets,
            user.originalAddress
          );
        }

        if (result) {
          unlock.canClaim = false;
          await this.getInfo();
        }
      }
    }
  }

  private isValidNetwork(networkId: number): boolean {
    if (this.isSolanaConnected || this.isTonConnected) return true;
    if (networkId != this.web3.currentNetworkDecimalValue) {
      this.web3.changeNetwork(networkId);
      return false;
    }
    return true;
  }

  public async changeWallet(lock: LockDTO): Promise<any> {
    if (!this.isValidNetwork(lock.networkId)) return;
    const claimingWallets = await this.api
      .getWallets(lock.id)
      .pipe(
        catchError((error) => {
          this.popupService.errorMessage(error);
          return of([]);
        })
      )
      .toPromise();
    if (!claimingWallets.length) return;

    let addressLockData;

    if (this.isConnected)
      addressLockData = claimingWallets.filter(
        (i) =>
          i.address.toLocaleLowerCase() ==
          this.web3.currentAccountValue.toLowerCase()
      )[0];
    if (this.isSolanaConnected)
      addressLockData = claimingWallets.filter(
        (i) => i.address == this.solanaV3.currentAccountValue
      )[0];
    if (this.isTonConnected)
      addressLockData = claimingWallets.filter(
        (i) => i.address == this.ton.currentAccountValue
      )[0];

    if (!addressLockData) {
      return;
    }

    // might be would be required later
    // if(addressLockData.pendingAddress && this.newWallet.toLowerCase() != this.web3.currentAccountValue.toLowerCase()){
    //   this.popupService.pendingWalletWarning(true);
    //   return;
    // }

    this.newWallet = await this.popupService.confirmWalletChange();

    if (!this.newWallet) {
      return;
    }

    const existanceCheck = claimingWallets.filter((i) =>
      this.isConnected
        ? i.address.toLowerCase() == this.newWallet.toLowerCase()
        : i.address == this.newWallet
    );
    if (existanceCheck && existanceCheck.length > 0) {
      this.popupService.duplicateWalletWarning();
      return;
    }

    //todo move to admin control
    const wallets = this.getWalletArray(claimingWallets);
    console.log('wallets', wallets);
    if (this.isConnected)
      await this.web3.updateWallet(
        lock.version,
        addressLockData.amount,
        this.newWallet,
        wallets,
        lock.contractAddress,
        addressLockData?.originalAddress
      );

    if (this.isSolanaConnected)
      await this.solana(lock).changeWallet(
        lock.contractAddress,
        this.newWallet,
        addressLockData?.originalAddress
      );

    if (this.isTonConnected) {
      let result = await this.ton.changeWallet(
        lock.contractAddress,
        lock.claimingWallets,
        this.newWallet
      );

      if (!result) {
        return;
      }
    }

    const updatedWallet: WalletDTO = {
      id: addressLockData.id,
      address: this.newWallet,
      amount: addressLockData.amount,
    };

    const response = await this.api
      .updateWallet(lock.id, updatedWallet)
      .pipe(
        catchError((error: any) => {
          //todo handle error ?
          return of(null);
        })
      )
      .toPromise();
    this.popupService.pendingWalletWarning();
    this.lockInfo.isUserParticipated = false;
    console.log('response', response);
  }

  public getDate(unix: number): string {
    return new Date(unix).toLocaleString();
  }

  public unlockPassed(unlock: UnlockDTO): boolean {
    return unlock.startDate * 1000 <= new Date().getTime();
  }

  public isVestingStoppedAfterUnlockStarted(unlock: UnlockDTO): boolean {
    if (this.vestingStoppedTime === '0' || !this.vestingStoppedTime) {
      return true;
    } else {
      return (
        new Date(unlock.startDate * 1000) <=
        new Date(this.vestingStoppedTime * 1000)
      );
    }
  }

  public async isRequestRefunded(): Promise<void> {
    if (this.isConnected) {
      this.isRefunded = this.lockInfo.version === 2 ? await this.web3.isRefunded(this.lockInfo.contractAddress) : false;
    }
    else if (this.isSolanaConnected) {
      this.isRefunded = await this.solana(this.lockInfo).isRefunded(this.lockInfo.contractAddress);
    }
    else if (this.isTonConnected) {
      this.isRefunded = await this.ton.isRefunded(this.lockInfo.contractAddress);
    }
  }

  public handleCountDownEndsEvent($event: any, unlock: UnlockDTO): void {
    unlock.isTimerEnds = true;
    unlock.canClaim = true;
    if (unlock.periodUnit) {
      unlock.timeLeft = this.getTimeLeft(unlock);
    }
  }

  private getTimeLeft(unlock: UnlockDTO): number {
    const current = new Date().getTime();

    const diff = unlock.endDate * 1000 - current;
    if (diff > 0) return (diff / 1000) % unlock.periodUnit;
    return -1;
  }

  private calculateTimeLeft(unlock: UnlockDTO): number {
    const diff = Math.round(
      (unlock.startDate * 1000 - new Date().getTime()) / 1000
    );
    if (diff <= 0) {
      if (unlock.periodUnit) return this.getTimeLeft(unlock);
      else return -1;
    }
    return diff;
  }

  public canBeClaimed(unlock: UnlockDTO): boolean {
    return (
      !this.isAirdropped(unlock) &&
      this.unlockPassed(unlock) &&
      this.isVestingStoppedAfterUnlockStarted(unlock) &&
      !unlock.isClaimed &&
      new BigNumber(this.lockInfo.availableToClaim).gte(1)
    );
  }

  public async getAvailableAmount(lock: LockDTO): Promise<any> {
    let user, availableToClaim;

    if (this.isConnected)
      availableToClaim = await this.web3.getAvailableTokens(
        lock.version,
        this.getWalletAllocation(),
        lock.contractAddress,
        this.lockInfo.proof
      );
    if (this.isSolanaConnected)
      availableToClaim = await this.solana(lock).getAmountAvailableToClaim(
        this.lockInfo.contractAddress,
        this.solanaV3.currentAccountValue,
        this.totalAmount,
        this.lockInfo.tokenDecimals,
        this.lockInfo
      );
    if (this.isTonConnected)
      availableToClaim = await this.ton.getAmountAvailableToClaim(
        this.lockInfo.contractAddress,
        this.ton.currentAccountValue,
        this.totalAmount,
        this.lockInfo.tokenDecimals,
        this.lockInfo
      );

    this.lockInfo.availableToClaim = availableToClaim;
    await this.getClaimedAmount();
  }

  public async checkIfLockStopped(): Promise<any> {
    if (this.lockInfo.version >= 1 && this.isConnected) {
      this.lockInfo.isStopped = await this.web3.getIfVestingStopped(
        this.lockInfo.version,
        this.lockInfo.contractAddress
      );
    }
    if (this.isSolanaConnected) {
      this.lockInfo.isStopped = await this.solana(this.lockInfo).hasStopped(this.lockInfo);
    }
    if (this.isTonConnected) {
      this.lockInfo.isStopped = await this.ton.hasStopped(this.lockInfo);
    }
  }

  public async connectWallet(): Promise<void> {
    const dialogRef = this.dialog.open(SelectWalletComponent, {
      scrollStrategy: new NoopScrollStrategy(),
    });
  }

  public alertCanceled(): void {
    this.popupService.vestingCanceled();
  }

  public alertCantClaim(): void {
    const dateString = this.lockInfo?.date ? moment(this.lockInfo?.date).utc().format('MMM DD, YYYY, HH:mm:ss') : undefined;
    this.popupService.whyCantClaim(this.lockInfo?.percentage, dateString, this.lockInfo?.coin, this.lockInfo?.refundType, this.isRefunded, this.isConnected);
  }

  public redirectRefunded():void{
    if(this.lockInfo.coin == 'usdt'){
      window.open(`https://bscscan.com/token/0x55d398326f99059ff775485246999027b3197955?a=${this.web3.currentAccountValue}`, '_blank')
    }
    else
      window.open(`https://bscscan.com/token/0xe9e7cea3dedca5984780bafc599bd69add087d56?a=${this.web3.currentAccountValue}`, '_blank')
  }

  public async requestRefund(lockInfo: LockDTO): Promise<void> {
    if (!this.isValidNetwork(lockInfo.networkId)) return;

    if (await this.popupService.confirmRefundPopup(lockInfo)) {
      try {
        let res ;

        if (lockInfo.blockchain === "ton") {
          res = await this.ton.requestRefund(lockInfo.contractAddress, lockInfo.claimingWallets);
        }
        else if (lockInfo.blockchain === "solana") {
          res  = await this.solana(this.lockInfo).requestRefund(lockInfo.contractAddress);
        }
        else {
          res = await this.web3.requestRefund(lockInfo.contractAddress);
        }

        console.log('Refund Request result: ', res);
        this.popupService.refundSubmittedPopup();
      }
      catch (e: any) {
        if (!this.isRejected(e)) {
          this.popupService.errorMessage("Unable to request refund: " + e.message);
        }
      }
    }

    await this.isTokenRefunded(lockInfo);
  }

  isRefundVisible(lock: LockDTO): boolean {
    const currentDate = new Date();
    const startDate = new Date(lock.startDate);
    const refundDeadlineDate = new Date(lock.refundDate);

    const sevenDaysAfterUnlock = new Date(startDate.getTime() + 168 * 60 * 60 * 1000);

    return currentDate >= startDate && currentDate <= sevenDaysAfterUnlock && currentDate <= refundDeadlineDate;
  }

  isRefundPast(lock: LockDTO): boolean {
    if (lock.refundDate) {
      const currentDate = new Date();
      const refundDeadlineDate = new Date(lock.refundDate);
      return currentDate > refundDeadlineDate;
    } else return true;
  }

  showWarningPopup() {
    return swalert.fire({
      title: 'Error',
      html:
        'Sorry, you can not request a refund because you have already claimed the first unlock!',
      icon: 'error',
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: 'Close',
    });
  }

  isTokenClaimed(): boolean {
    return this.unlockGroups.some((group: any) =>
      group.some((token: any) => token.isClaimed)
    );
  }

  public async isTokenRefunded(lock: LockDTO): Promise<boolean> {
    let isRefundRequested: boolean;

      if (lock.blockchain === "ton") {
        isRefundRequested = await this.ton.isRefundRequested(lock.contractAddress);
      }
      else if (lock.blockchain === "solana") {
        isRefundRequested = await this.solana(lock).isRefundRequested(lock.contractAddress);
      }
      else {
        isRefundRequested = (lock.version === 2 ? await this.web3.isRefundRequested(lock.contractAddress) : false);
      }

    this.isRefundRequested = isRefundRequested;
    return isRefundRequested;
  }

  public async getClaimedAmount(): Promise<any> {
    let claimedAmount, existanceCheck;

    if (this.isConnected)
      claimedAmount = await this.web3.getTotalClaimedPerWallet(
        this.lockInfo.version,
        this.web3.currentAccountValue,
        this.getWalletAllocation(),
        this.lockInfo.contractAddress,
        this.lockInfo.proof
      );
    if (this.isSolanaConnected) {
      claimedAmount = await this.solana(this.lockInfo).getTotalClaimedPerWallet(
        this.lockInfo.contractAddress
      );

      if (claimedAmount == '0') {
        let airdroppedBN = new BigNumber(0);
        let now = new Date().getTime() / 1000;
        let amountBN = new BigNumber(this.totalAmount);

        for (let unlock of this.lockInfo.unlocks) {
          if (unlock.isUnlockedBeforeStart && unlock.startDate < now) {
            let percentageBN = new BigNumber(unlock.percentage);
            let claimedBN = amountBN.multipliedBy(percentageBN).dividedBy(100 * SOLANA_TOKEN_PERCENTAGE_DECIMAL);
            airdroppedBN = airdroppedBN.plus(claimedBN);
          }
        }

        if (airdroppedBN.gt(0)) {
          claimedAmount = (new BigNumber(claimedAmount)).plus(airdroppedBN).toFixed();
        }
      }
    }
    if (this.isTonConnected) {
      claimedAmount = await this.ton.getTotalClaimedPerWallet(
        this.lockInfo.contractAddress,
        this.lockInfo.claimingWallets
      );
    }

    this.claimedAmount = claimedAmount;
    this.leftAmount = new BigNumber(this.totalAmount)
      .minus(new BigNumber(this.claimedAmount))
      .toString();

    let bnClaimedAmount = new BigNumber(claimedAmount);
    let bnTotalAmount = new BigNumber(this.totalAmount);
    let bnClaimedPecentage = bnClaimedAmount
      .div(bnTotalAmount)
      .times(new BigNumber('100'));
    this.claimedPercent = Math.round(bnClaimedPecentage.toNumber() * 100) / 100;
    let bnAvailPecentage = bnClaimedAmount
      .plus(new BigNumber(this.lockInfo.availableToClaim))
      .div(bnTotalAmount)
      .times(new BigNumber('100'));

    let iPercentage = 0;
    let decimals = EVM_TOKEN_PERCENTAGE_DECIMAL;

    if (this.lockInfo.blockchain == 'solana') {
      decimals = SOLANA_TOKEN_PERCENTAGE_DECIMAL;
    }
    else if (this.lockInfo.blockchain == 'ton') {
      decimals = TON_TOKEN_PERCENTAGE_DECIMAL;
    }

    const unlocks = this.lockInfo.unlocks.map((item: UnlockDTO) => {
      let percentage =
        item.percentage /
        (this.lockInfo.id <= 2
          ? 100
          : decimals);
      iPercentage += percentage;
      item.timeLeft = this.calculateTimeLeft(item);
      item.isClaimed = bnClaimedPecentage.gte(new BigNumber(iPercentage));
      item.isCanceled =
        this.lockInfo.isStopped &&
        bnAvailPecentage.lt(new BigNumber(iPercentage));
      return item;
    });
    this.unlockGroups = this.generateGroups(unlocks);
    console.log('unlocks', unlocks);
    console.log(
      `Amounts: total ${this.totalAmount}, claimed ${this.claimedAmount} (${this.claimedPercent}%)`
    );

    if (this.isConnected) existanceCheck = this.lockInfo.isUserParticipated;
    if (this.isSolanaConnected)
      existanceCheck = this.lockInfo.claimingWallets.filter(
        (i) => i.address == this.solanaV3.currentAccountValue
      );
    if (this.isTonConnected)
      existanceCheck = this.lockInfo.claimingWallets.filter(
        (i) => i.address == this.ton.currentAccountValue
      );
  }

  public calculateClaimed(unlock: UnlockDTO): number {
    let totalAlloc = new BigNumber(this.totalAmount);
    let claimed = new BigNumber(this.claimedAmount);
    let claimedPercentValue = this.claimedPercent;

    if (!claimed || claimed.eq(0) || claimed.lte(0) || claimedPercentValue == 0)
      return 0;

    const ptWithoutCurrent = this.lockInfo.unlocks
      .filter((i) => i.id < unlock.id)
      .reduce((i, j) => i + j.percentage, 0);
    const ptWithCurrent = ptWithoutCurrent + unlock.percentage;
    if (
      claimed.eq(totalAlloc) ||
      claimedPercentValue >=
      ptWithCurrent /
      (this.lockInfo.blockchain == 'solana'
        ? SOLANA_TOKEN_PERCENTAGE_DECIMAL
        : EVM_TOKEN_PERCENTAGE_DECIMAL)
    ) {
      return this.truncateDecimals(
        unlock.percentage /
        (this.lockInfo.blockchain == 'solana'
          ? SOLANA_TOKEN_PERCENTAGE_DECIMAL
          : EVM_TOKEN_PERCENTAGE_DECIMAL),
        2
      );
    }
    const calculatedPt =
      claimedPercentValue -
      ptWithoutCurrent /
      (this.lockInfo.blockchain == 'solana'
        ? SOLANA_TOKEN_PERCENTAGE_DECIMAL
        : EVM_TOKEN_PERCENTAGE_DECIMAL);
    if (calculatedPt < 0) return 0;
    return this.truncateDecimals(calculatedPt, 2);
  }

  private truncateDecimals(num: number, digits: number) {
    let numS = num.toString(),
      decPos = numS.indexOf('.'),
      substrLength = decPos == -1 ? numS.length : 1 + decPos + digits,
      trimmedResult = numS.substr(0, substrLength),
      finalResult = isNaN(+trimmedResult) ? 0 : trimmedResult;

    return +finalResult;
  }

  public copyAddress(): void {
    navigator.clipboard
      .writeText(this.lockInfo.tokenAddress)
      .then(() => {
        this.popupService.successMessage('Copied to Clipboard');
      })
      .catch((e) => {
        this.popupService.errorMessage('Something went wrong');
        console.error(e);
      });
  }

  public addTokenToMetamask(): void {
    this.web3.addTokenToMetamask(
      this.lockInfo.tokenAddress,
      this.lockInfo.tokenSymbol,
      this.lockInfo.tokenDecimals
    );
  }

  private getWalletAllocation(): any {
    const claimingWallets = this.lockInfo?.claimingWallets;
    let addressAmount;
    if (this.isSolanaConnected)
      addressAmount = claimingWallets?.filter(
        (i) => i.address == this.solanaV3.currentAccountValue
      )[0]?.amount;
    if (this.isTonConnected)
      addressAmount = claimingWallets?.filter(
        (i) => i.address == this.ton.currentAccountValue
      )[0]?.amount;
    if (this.isConnected) addressAmount = this.lockInfo.userTotalAllocation;
    return addressAmount || 0;
  }

  public getWalletArray(claimingWallets: any[]): string[][] {
    return claimingWallets
      .map((x) => [x.originalAddress || x.address, x.amount])
      .sort((a, b) => {
        if (a[0] < b[0]) {
          return -1;
        }
        if (a[0] > b[0]) {
          return 1;
        }
        return 0;
      });
  }

  private isRejected(e: any) {
    return this.isTonConnected &&  e?.message?.indexOf('Reject request') != -1; 
  }
}
