import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import BigNumber from "bignumber.js";
import { LockDTO } from "src/app/dto/lock.dto";
import { ApiService } from "src/app/services/api.service";
import { AuthService } from "src/app/services/auth.service";
import { ClaimService } from "src/app/services/claim.service";
import { PopupService } from "src/app/services/popup.service";
import { Web3Service } from "src/app/services/web3.service";
import { SolanaWeb3Service } from "src/app/services/solana.service";
import { WalletConnectorService } from "src/app/services/wallet-connector.service";
import { Papa } from 'ngx-papaparse';
import { SolanaWeb3ServiceV2 } from "src/app/services/solanav2.service";
import { SolanaWeb3ServiceV3 } from "src/app/services/solanav3.service";
import { TonService } from "src/app/services/ton.service";

@Component({
  selector: 'app-edit-claiming',
  templateUrl: './edit-claiming.component.html',
  styleUrls: ['./edit-claiming.component.scss'],
})
export class EditClaimingComponent {
  public lockInfo: LockDTO;
  public lockedBalance: string;
  public claimedPercent: number = 0;
  public editMode: boolean = false;
  public isStopped: boolean = false;

  public projectId:number;

  public newProjectName: string;
  public newProjectIcon: string;
  public newProjectPrice: string;
  public isHidden: boolean = null;
  public iconData: string;
  public refundType: 'full' | 'partial' | undefined;
  public refundUrl : string;
  public refundPercentage: number;
  public refundDate: Date;
  public refundCurrency : "busd" | "usdt";

  public depositValue: string;
  public withdrawValue: string;
  public refundList: string[];
  public setPause: boolean;

  public get isConnected(): boolean {
    return this.web3.isConnected && this.auth.isAuthorized();
  }

  public get isSolanaConnected(): boolean {
    return this.solana.isConnected && this.auth.isAuthorized();
  }

  public get isTonConnected(): boolean {
    return this.ton.isConnected && this.auth.isAuthorized();
  }

  constructor(
    private readonly web3: Web3Service,
    private readonly solanaV1: SolanaWeb3Service,
    private readonly solanaV2: SolanaWeb3ServiceV2,
    private readonly solanaV3: SolanaWeb3ServiceV3,
    private readonly ton: TonService,
    private readonly walletConnector: WalletConnectorService,
    private readonly auth: AuthService,
    private readonly api: ApiService,
    private readonly route: ActivatedRoute,
    private readonly popupService: PopupService,
    private readonly claimService: ClaimService,
    private papa: Papa) {

    this.projectId = this.route.snapshot.params["id"];
    this.api.getLock(this.projectId).subscribe(i => {
      this.lockInfo = i;
      this.refundType = this.lockInfo?.refundType;
      this.refundDate = this.lockInfo?.date;
      this.refundUrl = this.lockInfo?.url;
      this.refundPercentage = this.lockInfo?.percentage;
      this.refundCurrency = this.lockInfo?.coin;
      this.getTokenAdditionalInfo();
    });
  }

  ngOnInit(): void {
    this.walletConnector.initSolana();
    this.walletConnector.initTon();
  }

  private get solana() {
    return this.lockInfo.isV3 ? this.solanaV3 : (this.lockInfo.isV2 ? this.solanaV2 : this.solanaV1);
  }

  public async getTokenAdditionalInfo(){
    if (this.isConnected) this.lockedBalance = (await this.web3.getTokenInfo(this.lockInfo.tokenAddress, this.lockInfo.contractAddress)).balance;
    if (this.isSolanaConnected || this.isTonConnected) this.lockedBalance = '0';
    const bnClaimedAmount = new BigNumber(this.lockedBalance);
    console.log('total vesting amount >>>>', this.lockInfo.amount)
    const bnTotalAmount = new BigNumber(this.lockInfo.amount);
    /*
      we need to 'assume', how much is claimed based on contract balance
      if balance zero it means all claimed (atm, in case of partial funds deposit), or nothing was deposited
    */
    const bnClaimedPecentage = bnTotalAmount.minus(bnClaimedAmount).times(new BigNumber("100")).div(bnTotalAmount);
    this.claimedPercent = Math.round(bnClaimedPecentage.toNumber());

    if (this.lockInfo.version >= 1 && this.isConnected) {
      const isStopped = await this.web3.getIfVestingStopped(this.lockInfo.version, this.lockInfo.contractAddress);
      this.isStopped = isStopped;
    }

    if (this.isSolanaConnected) {
      const isStopped = await this.solana.hasStopped(this.lockInfo);
      this.isStopped = isStopped;
    }
    if (this.isTonConnected) {
      this.isStopped = await this.ton.hasStopped(this.lockInfo);
      this.withdrawValue = await this.ton.getAvailableAmount(this.lockInfo.vaultAddress);
      this.lockedBalance = this.withdrawValue;
    }
  }

  public async stopVesting() {
    try {
      if (this.isConnected) await this.web3.stopVesting(this.lockInfo.contractAddress);
      if (this.isSolanaConnected) {
        await this.solana.stopVesting(this.lockInfo.contractAddress, this.lockInfo.amount, this.lockInfo.tokenDecimals);
      }
      if (this.isTonConnected) {
        await this.ton.stopVesting(this.lockInfo.contractAddress, this.lockInfo.amount, this.lockInfo.tokenDecimals);
      }
      this.popupService.successMessage(`Successfully Stopped Vesting`);
      this.isStopped = true;
    } catch (error: any) {
      if (!this.isRejected(error)) {
        this.popupService.errorMessage(`Failed to Stop Vesting: ${error.message}` );
      }
    }
  }

  public setMaxDepositValue():void {
    const amount = this.lockInfo.claimingWallets.map(i=>i.amount as any).reduce((previousValue:string, currentValue: string) => BigInt(previousValue) + BigInt(currentValue), BigInt(0));
    this.depositValue = amount.toString().replace('n', '');
  }

  public async setMaxWithdrawValue():Promise<void> {
    let amount;

    if (this.isConnected) {
      amount = await this.web3.getTokensAmount(this.lockInfo.contractAddress, this.lockInfo.tokenAddress);
    }
    if (this.isSolanaConnected) {
      amount = await this.solana.getAvailableAmount(this.lockInfo.contractAddress);
    }
    else if (this.isTonConnected) {
      amount = await this.ton.getAvailableAmount(this.lockInfo.vaultAddress);
    }

    this.withdrawValue = amount;
  }

  public calculateDepositBalance(value:string, decimals: number): void{
    if(!value){
      this.depositValue = null;
    }
    this.depositValue = new BigNumber(value).shiftedBy(decimals).toString();
  }

  /*private parseCSV(readerResult: string): any {
    let allTextLines = readerResult.split(/\r|\n|\r/);
    const wallets = [];
    for (let i = 1; i < allTextLines.length; i++) {
      // split content based on comma
      let data = allTextLines[i].split(',');
      if(data[0])
        wallets.push(data[0]);
    }
    return wallets;
  }*/

  public onWalletsFileSelected(event: any): void {
    let reader: FileReader = new FileReader();

    reader.onload = (e) => {
      const fileContent = reader.result as string;
      this.refundList = this.parseCSV(fileContent);
    };

    reader.onloadend = (e) => {
      this.addRefunded();
    };

    reader.readAsText(event.target.files[0]);
  }

  private parseCSV(data: string): string[] {
    return data.split(',').map(address => address.trim());
  }

  public calculateWithdrawBalance(value:string, decimals: number): void{
    if(!value){
      this.withdrawValue = '0';
    }
    else {
      this.withdrawValue = new BigNumber(value).shiftedBy(decimals).toFixed();
    }
  }

  public edit():void{
    this.editMode = true;
  }

  public async save():Promise<void>{
    if(!this.newProjectName && this.isHidden == null && !this.newProjectIcon && !this.refundType && !this.newProjectPrice){
      console.log("return");
      this.editMode = false;
      return;
    }
    if(this.newProjectPrice){
      this.newProjectPrice = this.newProjectPrice.toString();
    }
    const updatedLock = await this.api.updateLock({id: this.projectId, projectName: this.newProjectName, projectPrice: this.newProjectPrice, projectIcon: this.newProjectIcon,  isHidden: this.isHidden, refundType: this.refundType, url: this.refundUrl, date: this.refundDate, percentage: this.refundType  == "partial" ? this.refundPercentage: 100, coin : this.refundCurrency}).toPromise();
    this.lockInfo = updatedLock;
    this.editMode = false;
    this.refundType = this.lockInfo.refundType;
  }

  public async depositFunds() {
    try {
      if (this.isConnected) {
        await this.web3.depositFundsToLock(this.lockInfo.contractAddress, this.lockInfo.tokenAddress, new BigNumber(this.depositValue));
      }
      else if (this.isTonConnected) {
        await this.ton.depositFunds(this.lockInfo.contractAddress, this.lockInfo.tokenAddress, Number(this.depositValue))
      }
      this.popupService.successMessage("Deposited successfully");
      this.getTokenAdditionalInfo();
    }
    catch (error: any) {
      if (!this.isRejected(error)) {
        this.popupService.errorMessage(`Deposit failed: ${error.message}`)
      }
    }
  }

  public addRefunded(): void {
    if (this.isTonConnected) {
      this.ton.addRefunded(this.lockInfo.contractAddress, this.refundList)
        .then(()=> this.handleAddRefunded(),
        (e)=> this.handleAddRefunded(e));
    }
    else if (this.isConnected) {
      this.web3.addRefunded(this.lockInfo.contractAddress, this.refundList)
        .then(()=> this.handleAddRefunded(),
        (e)=> this.handleAddRefunded(e));
    }
  }

  private handleAddRefunded(error: any = null) {
    if (error) {
      if (!this.isRejected(error)) {
        console.log(error);
        this.popupService.errorMessage(`Failed to add refund list: ${error.message}`)
      }
    }
    else {
      this.popupService.successMessage("Refund list added");
      this.getTokenAdditionalInfo();
    }
  }

  public emergencyWithdraw(): void{
    this.web3.emergencyWithdraw(this.lockInfo.version, this.lockInfo.contractAddress, this.lockedBalance)
      .then(()=>{
        this.popupService.successMessage("withdraw");
        this.getTokenAdditionalInfo();
      },
      ()=> this.popupService.errorMessage("fail")
    );
  }

  public async withdrawFunds(): Promise<void> {
    try {
      if (this.isConnected) {
        await this.web3.emergencyWithdraw(this.lockInfo.version, this.lockInfo.contractAddress, this.withdrawValue);
      }
      if (this.isSolanaConnected) {
        await this.solana.withdrawFunds(this.lockInfo.contractAddress, this.withdrawValue);
      }
      if (this.isTonConnected) {
        await this.ton.withdrawFunds(this.lockInfo.contractAddress, this.withdrawValue);
      }
      this.popupService.successMessage(`Tokens successfully withdrawn`);
      this.getTokenAdditionalInfo();

    } catch (error: any) {
      if (!this.isRejected(error)) {
        this.popupService.errorMessage(`Failed to withdraw tokens: ${error.message}`);
      }
    }
  }

  public async initConfig(): Promise<void> {
    try {
      if (this.isSolanaConnected) {
        let address = await this.solana.createConfig();
        this.popupService.successMessage(`Config created: ${address.toBase58()}`);
      }
    } catch (error) {
      console.log(error);
      this.popupService.errorMessage(`Failed to withdraw tokens`);
    }
  }

  public async exportRefundRequests(): Promise<void> {
    let result: any = null;

    if (this.isConnected) {
      result = await this.web3.exportRefundRequests(this.lockInfo.contractAddress);
    }
    else if (this.isSolanaConnected) {
      result = await this.solana.getRefundRequests(this.lockInfo.contractAddress);
    }
    else if (this.isTonConnected) {
      result = await this.ton.getRefundRequests(this.lockInfo.contractAddress);
    }

    const csvFile = this.papa.unparse([result]);

    const csvData = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
    const csvURL = URL.createObjectURL(csvData);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', 'refund_requests.csv');
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
  }

  public pauseClaiming(): void{
    this.web3.setPause(this.lockInfo.version, this.lockInfo.tokenAddress, this.setPause, this.lockInfo.contractAddress).then(()=>this.popupService.successMessage("done"), ()=> this.popupService.errorMessage("fail"))
  }

  public onProjectIconSelected(event: any): void {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.onload = (event: any) => {
        this.newProjectIcon = event.target.result;
      }

      reader.readAsDataURL(event.target.files[0]);
    }
  }

  private isRejected(e: any) {
    return this.isTonConnected &&  e?.message?.indexOf('Reject request') != -1;
  }
}
