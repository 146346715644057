import {Component, HostListener, ViewChild} from "@angular/core";
import { ApiService } from "src/app/services/api.service";
import { AuthService } from "src/app/services/auth.service";
import { SolanaWeb3Service } from "src/app/services/solana.service";
import { WalletConnectorService } from 'src/app/services/wallet-connector.service';
import { Web3Service } from "src/app/services/web3.service";
import { BehaviorSubject, combineLatest, forkJoin, from, Observable,of, OperatorFunction, pipe } from "rxjs";
import { LockDTO } from "src/app/dto/lock.dto";
import { catchError, concatMap,  map, mapTo, switchMap, tap } from "rxjs/operators";
import { PopupService } from "src/app/services/popup.service";
import { ClaimService } from "src/app/services/claim.service";
import { Router } from "@angular/router";
import { Blockchain } from "src/app/enums/blockchain_enum";
import { CdTimerComponent } from "angular-cd-timer";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import { SolanaWeb3ServiceV2 } from "src/app/services/solanav2.service";
import { SolanaWeb3ServiceV3 } from "src/app/services/solanav3.service";
import { TonService } from "src/app/services/ton.service";

declare var $: any;

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"]
})
export class MainComponent {

  // 1 - show timer :
  //                not authorized & something still locked
  //                authorized & something still locked & zero to claim
  // 2 - show claim :
  //                authorized && not zero to claim
  // 3 - show finished:
  //                authorized && zero to claim && nothing in lock
  //                not authorized && nothing in lock

  @ViewChild(MatPaginator) paginator: MatPaginator;


  public lockTypeValue = 0;

  public readonly projectTypeChangeTrigger$ = new BehaviorSubject(0);
  public readonly searchTrigger$ = new BehaviorSubject('');
  public readonly lockFilterTrigger$ = new BehaviorSubject(this.lockTypeValue); // 0 - all, 1 - user's
  public lockList : LockDTO[];

  locks: any
  pageSlice: any

  public get isConnected(): boolean {
    return this.web3.isConnected && this.auth.isAuthorized();
  }

  public get isSolanaConnected(): boolean {
    return this.solanaV3.isConnected && this.auth.isAuthorized();
  }

  public get isTonConnected(): boolean {
    return this.ton.isConnected && this.auth.isAuthorized();
  }

  public get lockType(): number {
    return this.lockTypeValue;
  }

  private solana(lock: LockDTO) {
    return lock.isV3 ? this.solanaV3 : (lock.isV2 ? this.solanaV2 : this.solanaV1);
  }

  public tabActive: boolean = true;
  private tabActive$ = new BehaviorSubject<boolean> (true);
  @HostListener('document:visibilitychange', ['$event'])
  public visibilitychange($event: any): void {
    if (document.hidden) {
      this.tabActive = false;
      this.tabActive$.next(false);
    } else {
      this.tabActive = true;
      this.tabActive$.next(true);
      //this.calculateUnlocksTimeLeft()
    }
  }

  private get getBlockChain(): Blockchain {
    if(this.isConnected){
      const networkId = parseInt(this.web3.currentNetworkValue);
      if(networkId == 1 || networkId == 4)
        return Blockchain.Ethereum;
      if(networkId == 56 || networkId == 97 || networkId == 321 || networkId == 322)
        return Blockchain.Binance;
      if(networkId == 137 || networkId == 8001)
        return Blockchain.Polygon;
      if(networkId == 43114 || networkId == 43113)
        return Blockchain.Avalanche;
      if(networkId == 42161 || networkId == 421614)
        return Blockchain.Arbitrum;
      if(networkId == 8453 || networkId == 84532)
        return Blockchain.Base;
      if(networkId == 81457 || networkId == 168587773)
        return Blockchain.Blast;
      if(networkId == 10242 || networkId == 10243)
        return Blockchain.Arthera;
    }
    if(this.isSolanaConnected)
      return Blockchain.Solana;

    if (this.isTonConnected) {
      return Blockchain.Ton
    }

    return -1;
  }

  private get getNetwork(): string{
    if(this.isConnected){
      return this.web3.currentNetworkValue;
    }
    if(this.isSolanaConnected){
      return this.solanaV3.currentNetworkValue;
    }
    if(this.isTonConnected){
      return this.ton.currentNetworkValue;
    }
    return '-1';
  }

  private readonly locksObservable = this.web3.sensetiveDataChanged$.pipe(
    switchMap(x => this.api.getLocks(this.getBlockChain, this.getNetwork)
      .pipe(this.getLocksInfo())));

  public locks$: Observable<any> = combineLatest([this.projectTypeChangeTrigger$, this.searchTrigger$, this.locksObservable, this.lockFilterTrigger$, this.tabActive$]).pipe(
    map(([type, search, data, lockFilter]) =>
      data
        .filter(i => this.auth.isAdmin() ? true : !i.isHidden)
        .filter(i => this.isConnected ? i.networkId == parseInt(this.getNetwork, 16) : true)
        .filter(i => this.isSolanaConnected ? i.blockchain == "solana" : true)
        .filter(i => this.isTonConnected ? i.blockchain == "ton" : true)
        .filter(i => type == 0 ? true : i.platform.id == type)
        .filter(i => search ? i.projectName.toString().toLocaleLowerCase().includes(search.toLowerCase()) || i.amount.toString().includes(search) : true)
        .filter(i => lockFilter == 0 ? true : i.isUserParticipated)
    ),
    tap(i=>i.forEach(k=>k.checktimeLeft()))
  );

  constructor(private readonly api: ApiService,
    private readonly web3: Web3Service,
    private readonly solanaV1: SolanaWeb3Service,
    private readonly solanaV2: SolanaWeb3ServiceV2,
    private readonly solanaV3: SolanaWeb3ServiceV3,
    private readonly ton: TonService,
    private readonly walletConnector: WalletConnectorService,
    private readonly auth: AuthService,
    private readonly router: Router,
    private readonly claimService: ClaimService,
    private readonly popupService: PopupService) {
    }
    async ngOnInit(): Promise<void> {
      await this.walletConnector.initTon();
      this.walletConnector.initSolana();
      
      this.locks$.subscribe((res) => {
        this.locks = res;
        this.pageSlice = this.locks.slice(0, 20);
      })
  }

  public sortColumn: string;
  public isDescSort: boolean = false;
  public isNumSort: boolean = false;
  public sort(sortColum: "projectName" | "blockchain" | "platform|name" | "amountReduced" | "allocationReduced" | "claimableReduced" | "startDate"){
    console.log('sort', sortColum);
    if(sortColum == this.sortColumn){
      this.isDescSort = !this.isDescSort;
    }else{
      this.isDescSort = sortColum == "amountReduced" || sortColum == "allocationReduced" || sortColum == "claimableReduced";
    }
    this.sortColumn = sortColum;
    this.locks = this.sortLocks(this.locks, this.sortColumn, this.isDescSort, this.isNumSort);
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    const endIndex = startIndex + this.paginator.pageSize;
    this.pageSlice = this.locks.slice(startIndex, endIndex > this.locks.length ? this.locks.length : endIndex);
  }

  onPageChange(event?: PageEvent) {
    const startIndex = event.pageIndex * event.pageSize;
    let endIndex = startIndex + event.pageSize;
    if (endIndex > this.locks.length) {
      endIndex = this.locks.length
    }
    this.pageSlice = this.locks.slice(startIndex, endIndex);
    this.scrollToTop();
  }

  sortLocks(input: [], sortKey?: string, descSort?: boolean, numSort?: boolean): any[] {
    const result = [...input].sort((a: any, b: any) => {
      if(!a || !b || !sortKey) return 0;
      //console.log(a, sortKey, a[sortKey], b[sortKey], typeof a[sortKey] === "bigint");
      let aValue = typeof a === 'object'
        ? sortKey.includes('|')
          ? a[sortKey.split('|')[0]][sortKey.split('|')[1]]
          : a[sortKey]
        : a;
      let bValue = typeof b === 'object'
        ? sortKey.includes('|')
          ? b[sortKey.split('|')[0]][sortKey.split('|')[1]]
          : b[sortKey]
        : b;

      if (numSort)
      {
        try {
          aValue = BigInt(aValue);
        }
        catch {
          aValue = BigInt(0)
        }

        try {
          bValue = BigInt(bValue);
        }
        catch {
          bValue = BigInt(0)
        }
      }

      if (aValue < bValue) {
        return !descSort ? -1 : 1;
      } else if (aValue > bValue) {
        return !descSort ? 1: -1;
      }

      return 0;
    });
    //console.log('result', result);
    return result;
  }

  scrollToTop(): void {
    const scrollOptions: ScrollToOptions = {
      top: 500,
      left: 0,
      behavior: 'smooth'
    };

    window.scrollTo(scrollOptions);
  }

  public formatNumber(number: number) {
    return number.toFixed(4).replace(/\B()(?=(\d{3})+(?!\d)\.)/g, " ");
  }

  private getUserClaim(lock: LockDTO, tryCount: number = 0): Observable<LockDTO | undefined> {
    const claimingWallets = lock.claimingWallets || [];
    const addressLockData = claimingWallets.filter(i => this.isTonConnected ? i.address == this.ton.currentAccountValue : (this.isSolanaConnected ? i.address == this.solanaV3.currentAccountValue : i.address.toLocaleLowerCase() == this.web3.currentAccountValue.toLowerCase()));
    if ((this.isSolanaConnected || this.isTonConnected) ? (lock.isUserParticipated && addressLockData.length) : lock.isUserParticipated) {
      let result: Promise<string>;

      if (this.isTonConnected) {
        result = this.ton.getAmountAvailableToClaim(lock.contractAddress, this.ton.currentAccountValue, addressLockData[0].amount, lock.tokenDecimals, lock);
      }
      else if (this.isSolanaConnected) {
        result = this.solana(lock).getAmountAvailableToClaim(lock.contractAddress, this.solanaV3.currentAccountValue, addressLockData[0].amount, lock.tokenDecimals, lock);
      }
      else if (this.isConnected) {
        result = this.web3.getAvailableTokens(lock.version, lock.userTotalAllocation, lock.contractAddress, lock.proof);
      }

      return from(result).pipe(
        map(amount => {
          lock.availableToClaim = amount;
          return lock;
        }),
        catchError((err: any, caught: Observable<LockDTO>) => {
          console.log('getAvailableTokens', err);
          if (tryCount < 3) {
            return this.getUserClaim(lock, tryCount + 1);
          }
          lock.availableToClaim = "unknown";
          return of(lock);
        }));
    }
    return of(lock);
  }

  private isRequestRefunded(lock: LockDTO) {
    if (lock.blockchain === "ton") {
      return from(this.ton.isRefunded(lock.contractAddress)).pipe(
        map(i => {
          lock.isRefunded = i;
          return lock;
        }),
        catchError((err: any, caught: Observable<LockDTO>) => {
          console.log('checkIfRefunded failed for', lock.contractAddress, err.message);
          lock.isRefunded = false;
          return of(lock);
        })
      )
    }
    else if (lock.version === 2 && this.isConnected) {
      return from(this.web3.isRefunded(lock.contractAddress)).pipe(
        map(i => {
          lock.isRefunded = i;
          return lock;
        }),
        catchError((err: any, caught: Observable<LockDTO>) => {
          console.log('checkIfRefunded failed for', lock.contractAddress, err.message);
          lock.isRefunded = false;
          return of(lock);
        })
      )
    }
    lock.isRefunded = false;
    return of(lock);
  }

  private checkIfLockStopped(lock: LockDTO): Observable<LockDTO | undefined> {
    if ((lock.version >= 1 ) || this.isSolanaConnected || this.isTonConnected) {
      let result: Promise<boolean>;
      
      if (lock.blockchain === "ton") {
        result = this.ton.hasStopped(lock);
      }
      else if (lock.blockchain === "solana") {
        result = this.solana(lock).hasStopped(lock);
      }
      else {
        result = this.web3.getIfVestingStopped(lock.version, lock.contractAddress);
      }
      
      return from(result).pipe(
        map(i => {
          lock.isStopped = i;
          return lock;
        }),
        catchError((err: any, caught: Observable<LockDTO>) => {
          //console.log('checkIfLockStopped', err);
          return of(lock);
        })
      )
    }
    lock.isStopped = false;
    return of(lock);
  }

  public changeType(type: number): void {
    this.projectTypeChangeTrigger$.next(type);
  }

  public changeLockFilter(type: number): void {
    this.lockFilterTrigger$.next(type);
    this.lockTypeValue = type;
  }

  public changeSearch($event: any): void {
    this.searchTrigger$.next($event.target.value);
  }

  public editAvailable(): boolean {
    return this.auth.isAdmin();
  }

  public handleCountDownEndsEvent(timerCompRef: CdTimerComponent, lock: LockDTO): void {
    lock.checktimeLeft();

    if(lock.timeLeft > 0){
      timerCompRef.startTime = lock.timeLeft;
      timerCompRef.start();
    }
  }

  public lockIdTrackFunction(index:number, lock:LockDTO){
    return lock?.id;
  }

  public showTimer(lock: LockDTO): boolean {
    const current = new Date();
    const showTimer = lock.unlocks.filter(i => i.periodUnit ? i.endDate *1000 > current.getTime() :  i.startDate * 1000 > current.getTime()).length > 0;
    return showTimer;
  }

  public showClaimButton(lock: LockDTO): boolean {
    return lock.claimable;
  }

  public showFinished(lock: LockDTO): boolean {
    return !lock.claimable;
  }

  public async claimToken(lock: LockDTO): Promise<any> {
    let user, result = false;
    lock.isLoading = true;
    if (this.isConnected) {
      result = await this.claimService.claimToken(lock);
    }
    if (this.isSolanaConnected) {
      user = lock.claimingWallets.filter(i => i.address == this.solanaV3.currentAccountValue)[0];
      result = await this.solana(lock).claimToken(lock.contractAddress, lock.claimingWallets, user.originalAddress);
    }
    if (this.isTonConnected) {
      user = lock.claimingWallets.filter(i => i.address == this.ton.currentAccountValue)[0];
      result = await this.ton.claimToken(lock.contractAddress, lock.vaultAddress, lock.claimingWallets, user.originalAddress);
    }
    lock.isLoading = false;
  }

  public getAdressLockAmount(lock: LockDTO): string {
    const claimingWallets = lock.claimingWallets;
    const addressLockData = claimingWallets.filter(i => this.isConnected ? i.address.toLocaleLowerCase() == this.web3.currentAccountValue.toLowerCase() : i.address == this.solanaV3.currentAccountValue);
    return addressLockData[0]?.amount;
  }

  private getLocksInfo(): OperatorFunction<LockDTO[], LockDTO[]> {
    return pipe(
      switchMap(i => (this.isTonConnected ? this.ton.currentNetwork$ : (this.isSolanaConnected ? this.solanaV3.currentNetwork$ : this.web3.currentNetwork$)).pipe(mapTo(i))),

      concatMap((locks: LockDTO[]) => {
        return locks.length ? forkJoin(locks.map(lock => this.checkIfLockStopped(lock))) : of([])
      }),

      concatMap((locks:LockDTO[]) => {
        return locks.length ? (this.isConnected) ? forkJoin(locks.map(lock=> lock.isUserParticipated ? this.getProof(lock) : of(lock))) : of(locks) : of([])
      }),

      concatMap((locks: LockDTO[]) => {
        return locks.length ? (this.isConnected || this.isSolanaConnected || this.isTonConnected) ? forkJoin(locks.map(lock => lock.isUserParticipated ? this.getUserClaim(lock) : of(lock))) : of(locks): of([])
      }),

      concatMap((locks: LockDTO[]) => {
        return locks.length ? (this.isConnected || this.isTonConnected) ? forkJoin(locks.map(lock => this.isRequestRefunded(lock))) : of(locks): of([])
      }),

      map(i => i.filter(k => !!k)),
    );
  }

  private getProof(lock: LockDTO): Observable<LockDTO | undefined> {
    return from((this.isSolanaConnected || this.isTonConnected) ? undefined : this.api.getProofs(lock.id)).pipe(
      map(proof => {
        lock.proof = proof;
        return lock;
      }))
  }
}
